import React, { useState } from 'react'
import GalleryBackground from '../../../components/galleryBackground'
import poemRo from '../../../images/rollovers/poem-ro.png'
import LongImgModal from '../../../components/longImgModal'
import anneFrank from '../../../images/writing/poems/anne_frank.jpg'
import catacombs from '../../../images/writing/poems/catacombs.jpg'
import immolation from '../../../images/writing/poems/immolation.jpg'
import penniman from '../../../images/writing/poems/on_seeing_mr_penniman.jpg'
import venus from '../../../images/writing/poems/venus.jpg'
import "../../layout.css"
import { Link } from "gatsby" 

export default function Poems() {
    const [modalState, setModalState] = useState({imgSrc:undefined, open:false})

    const handleClose = () => {
        setModalState(oldState => [{...oldState, open:false}])
    }

    return (
        <GalleryBackground title="Poems" imgSrc={poemRo}>
            <div className="columns-container">
                <Link to="/writing/poems/on-seeing-mr-penniman">
                    <h2>on seeing mr. penniman</h2>
                </Link>
                <Link to="/writing/poems/venus-of-willendorf">
                    <h2>venus of willendorf</h2>
                </Link>
                <Link to="/writing/poems/catacombs">
                    <h2>catacombs</h2>
                </Link>
                <Link to="/writing/poems/anne-franks-wall">
                    <h2>anne frank's wall</h2>
                </Link>
                <Link to="/writing/poems/jan-palach">
                <h2>the immolation of the martyr 
                jan palach and his subsequent journey 
                to his namesake asteroid, 1969</h2>
                </Link>
            </div>
            <LongImgModal modalState={modalState} handleClose={handleClose} />
        </GalleryBackground>
    )
}
